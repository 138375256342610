// ##############################
// // // RegularCard styles
// #############################

import {
    card,
    cardHeader,
    defaultFont,
    orangeCardHeader,
    greenCardHeader,
    redCardHeader,
    blueCardHeader,
    purpleCardHeader
} from '../material-dashboard-react.js';

const regularCardStyle = {
    card: {
        ...card,
        overflow: 'visible'
    },
    cardPlain: {
        background: 'transparent',
        boxShadow: 'none'
    },
    cardHeader: {
        ...cardHeader,
        ...defaultFont
    },
    cardPlainHeader: {
        marginLeft: 0,
        marginRight: 0
    },
    orangeCardHeader,
    greenCardHeader,
    redCardHeader,
    blueCardHeader,
    purpleCardHeader,
    cardTitle: {
        color: '#FFFFFF',
        marginTop: '0',
        marginBottom: '5px',
        ...defaultFont,
        fontSize: '1.125em'
    },
    cardSubtitle: {
        ...defaultFont,
        marginBottom: '0',
        color: 'rgba(255, 255, 255, 0.62)',
        margin: '0 0 10px'
    },
    cardActions: {
        padding: '14px',
        display: 'block',
        height: 'auto'
    }
};

export default regularCardStyle;
