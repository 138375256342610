import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardActions from '@material-ui/core/CardActions';
// core components
import regularCardStyle from '../../assets/jss/material-dashboard-react/regularCardStyle';

function RegularCard({ ...props }) {
    const {
        classes,
        headerColor,
        plainCard,
        cardTitle,
        cardSubtitle,
        content,
        footer
    } = props;
    const plainCardClasses = classNames({
        [' ' + classes.cardPlain]: plainCard
    });
    const cardPlainHeaderClasses = classNames({
        [' ' + classes.cardPlainHeader]: plainCard
    });
    return (
        <Card className={classes.card + plainCardClasses}>
            <CardHeader
                classes={{
                    root:
                        classes.cardHeader +
                        ' ' +
                        classes[headerColor + 'CardHeader'] +
                        cardPlainHeaderClasses,
                    title: classes.cardTitle,
                    subheader: classes.cardSubtitle
                }}
                title={cardTitle}
                subheader={cardSubtitle}
            />
            <CardContent>{content}</CardContent>
            {footer !== undefined ? (
                <CardActions className={classes.cardActions}>
                    {footer}
                </CardActions>
            ) : null}
        </Card>
    );
}

RegularCard.defaultProps = {
    headerColor: 'purple'
};

RegularCard.propTypes = {
    plainCard: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    headerColor: PropTypes.oneOf(['orange', 'green', 'red', 'blue', 'purple']),
    cardTitle: PropTypes.node,
    cardSubtitle: PropTypes.node,
    content: PropTypes.node,
    footer: PropTypes.node
};

export default withStyles(regularCardStyle)(RegularCard);
